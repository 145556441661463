export const SlideData = [
  {
    id: 'slide1',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Shing Chong & Co.',
    description:
      ' Pronounced "Sing Charn" (reads right to left). Opening shortly after the 1906 earthquake, Shing Chong & Co. operated for over 70 years in San Francisco\'s Chinatown. Located at 800 Grant Avenue it became one of Chinatown\'s most iconic stores.',
    img: require('../../images/store/shingchongsign.jpg'),
    alt: 'Shing Chong & Co. Sign',
    position: false,
    dark: true,
    primary: true,
    darkText: false,
  },

  {
    id: 'slide2',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Louie Wee Lee',
    description:
      'According to records, Louie Wee Lee came to America about 1900. He listed himself as the son of a merchant for a store named Boew Kee & Co. He returned to China, married and had a son, then returned alone in 1906',

    img: require('../../images/store/louie.jpg'),
    alt: 'Plane',
    flex: true,
    dark: true,
    primary: true,
    darkText: false,
  },

  {
    id: 'slide3',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Grant Ave. and Clay St.',
    description:
      "Before April 18, 1906, the corner of Dupont Ave. (now called Grant Ave) and Clay looked very different. A cable car line ran up and down Clay. The city's first hotel, the Portsmouth House, occupied the Northeast corner where the Shing Chong store would eventually be located.",

    img: require('../../images/store/clay_st.jpg'),
    alt: 'Dupont St. (not Grant Ave.)',
    dark: true,
    primary: true,
    darkText: false,
  },

  {
    id: 'slide4',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Cable Cars',
    description:
      'A cable car travels down Clay St. in front of Portsmouth Square',

    img: require('../../images/store/claycar.jpg'),
    img2: require('../../images/store/clayst.jpg'),
    alt: 'Dupont St. (not Grant Ave.)',
    alt2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide5',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: '800 Dupont St.',
    description: 'The corner of Dupont and Clay in 1880.',
    img: require('../../images/store/circa1880.jpg'),
    alt: 'Dupont St. (not Grant Ave.)',
    flex: true,
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide6',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'SS Mongolia',
    description: 'Louie Wee Lee returned to San Francisco on October 9, 1906.',
    img: require('../../images/store/SS-Mongolia.jpg'),
    alt: 'SS Mongolia',
    alt2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide7',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: '800 Dupont St.',
    description:
      'When he returned to San Francisco the store site at 800 Dupont (Grant) Ave looked like this.',
    img: require('../../images/store/clay_grant.jpg'),
    alt: '800 Dupont St.',
    alt2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide8',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Shing Ching & Co. Opens',
    description:
      'Five years later he started Shing Chong & Co. Louie Wee Lee (center right) is pictured in his store.',
    img: require('../../images/store/weeleeinsidestore.jpg'),
    alt: '5 years later',
    alt2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide9',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Louie at his desk',
    description:
      '"Louie was an industrious and frugal man, stern and forceful, but under that outer skin was a man who was respected and greeted as a good merchandiser with an innovative mind...Orders came from far and near as his prompt response and fair dealing to friend and stranger alike became known"',
    quote: '- Thomas Chinn, Asian Week Newspaper',
    img: require('../../images/store/LouieFrugal.jpg'),
    alt: 'Louie Wee Lee',
    alt2: '',
    flex: true,
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide10',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Chinese Deli',
    description:
      '"He was one of the first to experiment with Chinese delicatessen food. While such staples as roast duck and chicken, roast pig and cha siu were popular as early as the 1850\'s. Louie had his help start making his own Chinese sausage (lop cheung) and "bok shuey jee gek" (loosely translated as "white water pig\'s feet)"',
    quote: '- Thomas Chinn, Asian Week Newspaper',
    img: require('../../images/store/food.JPG'),
    alt: 'Chinese Deli',
    alt2: '',
    flex: true,
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide11',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Louie in front of his store',
    description:
      '"Louie was proud of his store and often boasted that "Shing Chong could provide all the necessities from birth, for death, and everything in between."',
    quote: '- Thomas Chinn, Asian Week Newspaper',
    img: require('../../images/store/about.jpg'),
    alt: 'Louie in front of store',
    alt2: '',
    flex: true,
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide12',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Chinese New Year',
    description:
      '"Early on, he started preparing for Chinese New Years with an elaborate array of Chinese water lilies. He innovatively nurtured them, fashioned them and then left them on the street stands in front of his store producing a heady aroma of pleasant smells as the passers-by viewed the swaying lilies"',
    quote: '- Thomas Chinn, Asian Week Newspaper',
    img: require('../../images/store/plants.JPG'),
    alt: 'water lilies',
    alt2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide13',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'WWII',
    description:
      '"During World War II, when importation of foodstuffs from the Far East was impossible, Louie came up with acceptable subsitutes such as Chinese brown sugar (wong tong). He turned his rooftop into a drying shed for such formerly imported items as dired duck (lop op), dehydrated cole..(choy gon), and..even salted fish (hom yee)."',
    quote: '- Thomas Chinn, Asian Week Newspaper',
    img: require('../../images/store/sailors3.jpg'),
    alt: 'World War II',
    // img2: require('../../images/store/wwII.jpg'),
    // alt2:'World War II',
    flex: true,
    position: true,
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide14',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Catering Business',
    description:
      'Two of his children remained to help their father run Shing Chong. Fay and Charles (note: Oldest son Robert also worked at the store for many years before passing away in 1957).',
    img: require('../../images/store/children.jpg'),
    alt: "Louie's children",
    img2: '',
    alt2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide15',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: '76 years in business',
    description:
      'After WWII, the sons added a whole new department to the repertoire of Shing Chong with a gleaming stainless steel kitchen advertising catering. It was an immediate success. The store, and later a catering business, operated for 76 years.',
    img: require('../../images/store/Portsmouthsquare.jpg'),
    alt: 'Portsmouth square',
    img2: require('../../images/store/storeback.jpg'),
    alt2: 'store',
    flex: true,
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide16',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Modern visions',
    description: 'Thought was given to modernization.',
    img: require('../../images/store/rainbow.jpg'),
    alt: 'Louie and son',
    img2: require('../../images/store/modern3.jpg'),
    alt2: 'Modern rendering of store',
    position: false,
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide17',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Asian Image in the 1980s',
    description:
      '"However in 1980, Charles passed away, and catering was gradually discontinued..." - Thomas Chinn, Asian Week Newspaper.',
    quote:
      'The store went on to operate for 6 more years, but the next generation of Louies, always willing to help, had their own career goals. Eventually the stote was closed and was sold in 1987.',
    img: require('../../images/store/asian-image3.jpg'),
    alt: 'Asian Image store',
    citation: '',
    img2: require('../../images/store/asian-image2.jpg'),
    alt2: 'Asian Image store',
    citation2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide18',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Chinese Exculsion Act',
    description:
      'With the passage of the 1882 Chinese Exclusion Ace and followed by numerous other acts for decades, Congress attempted to bar Chinese immigrants to America, especially laborers and women.',
    quote:
      'Even those legally in the country were subject to deportation or denial of re-entry if they travelled to China without proper documentation.',
    img: require('../../images/store/burbank.jpg'),
    alt: 'burbank print 1940',
    citation: '',
    img2: require('../../images/store/angel-island-1916.jpg'),
    alt2: 'Angle Island circa 1916',
    position: false,
    citation2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide19',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Angel Island',
    description:
      'The Angel Island Immigation Station was built in 1910 to screen arriving Chinese immigrants and deport those subject to the Chinese Exclusion Act. Intensive interrogations happened.',
    quote:
      'Those giving poor answers were subject to deportation even if eligible to return. There is an interesting story about "paper sons" not covered here.',
    img: require('../../images/store/angel-interrogate.jpeg'),
    citation: '',
    img2: require('../../images/store/angel_island.jpg'),
    alt2: 'angel island interrogation',
    position: false,
    citation2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide20',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Exemption for merchants',
    description:
      'Merchants were one group exempted from the Exclusion Act. Thus Wee Lee had the right to travel back and forth to China, marry and have children he could bring back.',
    img: require('../../images/store/docs.jpg'),
    alt: 'angel island interrogation',
    citation: '',
    // img2: require('../../images/store/docs.jpg'),
    // alt2:'angel island interrogation',
    position: true,
    flex: false,
    citation2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide21',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: '1913 Affidavidt for Entry',
    description: "1913 Affidavidt for Ng Shee and Robert's entry into the US.",
    img: require('../../images/louie/affidavit.jpg'),
    alt: 'Louie affidavit',
    citation: '',
    // img2: require('../../images/louie/ngshee.jpg'),
    // alt2:'Gone but not forgotten',
    flex: false,
    citation2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide22',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Louie Wee Lee & Ng Shee',
    description:
      'Louie Wee Lee, the founder of Shing Chong & Co. and his wife, and Ng Shee',
    img: require('../../images/louie/LouieWeeLee.jpg'),
    alt: 'Louie Wee Lee',
    citation: '',
    img2: require('../../images/louie/ngshee.jpg'),
    alt2: 'Louie Ng Shee',
    flex: true,
    citation2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide23',
    addImg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: '52 Store Partners',
    description:
      'Ultimately, Louie listed 52 or so partners in the store which meant that those people were also exempt and could travel back and forth to China and establish families.',
    quote:
      "Today, hundreds, perhaps thousands of Chinese decendants are U.S. citizens as a result of Louie Wee Lee's intrepid efforts. Here is a log of people who used Shing Chong to travel from Angel Island Immigation Station.",
    img: require('../../images/store/docs3.jpg'),
    alt: 'log',
    citation: '',
    img2: '',
    alt2: '',
    citation2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
  {
    id: 'slide24',
    addImg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Store: Shing Chong & Co.',
    headline: 'Louie Wee Lee 1881-1970',
    description:
      'Shing Chong & Co. Founder. Kudos to the man who was not only the founder of the Louie Clan in America, but successfully operated a beneficial business despite earthquakes, discrimination, two World Wars, Depression, prohibition, and personal hardships.',
    img: require('../../images/store/weefounder.jpg'),
    alt: 'Louie Wee Lee',
    citation: '',
    img2: require('../../images/store/gone.jpg'),
    alt2: 'Gone but not forgotten',
    // flex: true,
    citation2: '',
    dark: true,
    primary: true,
    darkText: false,
  },
];
